export type TRegion = {
  id: string
  slug: string
  // There is also a label property included in the response, but it is not used in the app
  // since its in the language of the region. Instead, we use the Intl.DisplayName object with
  // the slug to get the region name in English.
}

const REGION_NAMES_IN_ENGLISH = new Intl.DisplayNames(['en'], {
  type: 'region',
})

export const regionNameForSlug = (slug: string) => {
  if (slug === 'gl') {
    return 'Global'
  }
  return REGION_NAMES_IN_ENGLISH.of(slug.toUpperCase())
}
