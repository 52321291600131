import { BarChart } from '@changex/design-system'
import { TSolutionApplicationsOverTime } from 'shared/api/analytics'

type TProps = {
  data: TSolutionApplicationsOverTime
}

const getCountPerYear = (data: TSolutionApplicationsOverTime) => {
  return Object.keys(data).reduce((acc, key) => {
    const year = key.slice(0, 4)
    if (!acc[year]) {
      acc[year] = 0
    }
    acc[year] += data[key].length
    return acc
  }, {} as Record<string, number>)
}

const ApplicationsPerYear: React.FC<TProps> = ({ data }) => {
  const counts = getCountPerYear(data)
  return (
    <div className="flex flex-col rounded bg-white p-4">
      <h3 className="text-lg text-gray-800">Applications per year</h3>
      <div className="mx-4 mb-8 mt-12">
        <BarChart labels={Object.keys(counts)} data={Object.values(counts)} />
      </div>
    </div>
  )
}

export default ApplicationsPerYear
