import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useQuery as useJsonApiQuery } from 'jsonapi-react'
import { merge } from 'lodash'
import { AppLayout } from '@layouts'
import ListPage from '../pages/list.page'
import { useNotes, useSolutionById } from '../hooks'
import { TApplication } from '../types'
import { useEffect, useState } from 'react'
import { utils } from '../utils'
import { contants } from '../constants'
import { Toaster } from 'react-hot-toast'
import { Container } from 'shared/components'

const ListContainer = () => {
  const { solutionId } = useParams()
  const [filters, setFilters] = useState(
    merge(contants.defaultFilters, { filter: { solution_id: solutionId } })
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const location = useLocation()

  if (!solutionId) {
    throw new Error(
      'The solutionId is required to list all applications for one specific solution'
    )
  }

  const { data: solution, isLoading: loadingSolution = true } =
    useSolutionById(solutionId)

  const {
    data,
    meta,
    isLoading: loadingApplications = true,
    refetch,
  } = useJsonApiQuery<TApplication[]>(['applications', filters])

  const { putChangexNotes, putTeamNotes } = useNotes()

  const onSearchApplications = (id, newFilters) => {
    const formattedFilters = utils.normalizeFiltersToJsonApi(
      newFilters,
      filters
    )

    Object.keys(newFilters).forEach((key) => {
      const value = newFilters[key]
      if (value !== undefined && value !== '' && value !== null && value) {
        searchParams.set(key, newFilters[key])
      } else {
        searchParams.delete(key)
      }
    })

    const final = merge(filters, formattedFilters)
    setSearchParams(searchParams)
    setFilters({ ...final })
  }

  useEffect(() => {
    const newFilter: any = merge(filters, {
      filter: {
        solution_id: solutionId,
      },
      page: {
        number: page,
      },
    })
    setFilters(newFilter)
  }, [solutionId, page, location, filters])

  useEffect(() => {
    if (refetch) {
      refetch()
    }
    // eslint-disable-next-line
  }, [filters])

  return (
    <AppLayout>
      <Container>
        <ListPage
          meta={meta}
          applications={data ?? []}
          loadingApplications={loadingApplications}
          loadingSolution={loadingSolution}
          solution={solution}
          onSearchApplications={onSearchApplications}
          onChangexNotes={putChangexNotes}
          onTeamNotes={putTeamNotes}
        />
        <Toaster />
      </Container>
    </AppLayout>
  )
}

export default ListContainer
