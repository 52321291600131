import { BarChartTable } from '@changex/design-system'
import { TSolutionApplicationsOverTimeItem } from 'shared/api/analytics'
import classNames from 'classnames'
import { ComponentProps } from 'react'
import { TRegion, regionNameForSlug } from 'shared/api/regions'

type TProps = {
  regions: TRegion[]
  data: TSolutionApplicationsOverTimeItem[]
  className?: string
}

const groupDataByRegion = (
  regions: TRegion[],
  data: TSolutionApplicationsOverTimeItem[]
) => {
  return data.reduce((acc, item) => {
    if (!item.regionId) {
      return acc
    }
    const region = regions.find(
      (region) => region.id === item.regionId?.toString()
    )
    if (!acc[item.regionId]) {
      acc[item.regionId] = {
        label: regionNameForSlug(region!.slug),
        total: 0,
        stats: {
          total: 0,
        },
      }
    }
    acc[item.regionId].total++
    acc[item.regionId].stats.total++
    return acc
  }, {} as Record<number, ComponentProps<typeof BarChartTable>['rows'][0]>)
}

const ApplicationsPerRegion: React.FC<TProps> = ({
  regions,
  data,
  className,
}) => {
  const rows = Object.values(groupDataByRegion(regions, data)).sort(
    (a, b) => b.total - a.total
  )
  return (
    <div
      className={classNames('flex flex-col rounded bg-white p-4', className)}
    >
      <BarChartTable
        title="Applications per region"
        primaryColumnLabel="Region"
        legend={[
          {
            label: 'Total',
            color: 'bg-green-500',
            key: 'total',
          },
        ]}
        rows={rows}
      />
    </div>
  )
}

export default ApplicationsPerRegion
