import { AppLayout } from 'shared/layouts'
import { Toaster } from 'react-hot-toast'
import { Heading, Skeleton } from '@changex/design-system'
import { useParams } from 'react-router-dom'
import { useFetcher } from 'shared/api/fetcher'
import {
  TImpactAnalytics,
  TSolutionApplicationsAnalytics,
} from 'shared/api/analytics'
import { useSolutionById } from 'features/applications/hooks'
import SimpleStats from './SimpleStats'
import ApplicationsOverTime from './ApplicationsOverTime'
import ApplicationsPerYear from './ApplicationsPerYear'
import ApplicationsPerFund from './ApplicationsPerFund'
import ApplicationsPerRegion from './ApplicationsPerRegion'
import { TSolution } from '@features/applications/types'
import { TRegion } from 'shared/api/regions'

const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton h="152px" className="col-span-2" />
      <Skeleton h="408px" className="mt-2" />
      <Skeleton h="408px" className="mt-2" />
      <Skeleton h="460px" className="col-span-2" />
      <Skeleton h="460px" className="col-span-2" />
    </>
  )
}

const fundedTeamsCount = (aggregates: TSolution['aggregates']) => {
  return (
    (aggregates['paidSeed'] ?? 0) +
    (aggregates['impact'] ?? 0) +
    (aggregates['paidImpact'] ?? 0)
  )
}

const Page = () => {
  const { solutionId } = useParams()
  const { data: impactAnalytics, isLoading: isLoadingImpactAnalytics } =
    useFetcher<TImpactAnalytics>('/analytics/solutions/impacts', {
      'filter[id]': solutionId!,
    })
  const {
    data: applicationAnalytics,
    isLoading: isLoadingApplicationAnalytics,
  } = useFetcher<TSolutionApplicationsAnalytics>(
    '/analytics/solutions/applications',
    {
      'filter[id]': solutionId!,
    }
  )
  const { data: solution, isLoading: isLoadingSolution } = useSolutionById(
    solutionId!
  )
  const { data: regions, isLoading: isLoadingRegions } =
    useFetcher<TRegion[]>('/regions')
  const isLoading =
    isLoadingImpactAnalytics ||
    isLoadingApplicationAnalytics ||
    isLoadingSolution ||
    isLoadingRegions

  return (
    <AppLayout>
      <main>
        <div className="flex justify-between border-b border-gray-100 px-4 py-8">
          <Heading>Dashboard</Heading>
        </div>
        <div className="bg-copper-50 grid grid-cols-2 gap-4 p-4">
          {isLoading && <LoadingSkeleton />}
          {impactAnalytics && solution && (
            <SimpleStats
              className="col-span-2"
              completedSurveys={impactAnalytics.completedSurveys}
              totalApplications={Object.values(solution.aggregates).reduce(
                (a, b) => a + b,
                0
              )}
              fundedTeams={fundedTeamsCount(solution.aggregates)}
              {...impactAnalytics.peopleImpactedAggregates}
            />
          )}
          {applicationAnalytics && regions && (
            <>
              <ApplicationsOverTime
                data={applicationAnalytics.applicationsOverTime}
              />
              <ApplicationsPerYear
                data={applicationAnalytics.applicationsOverTime}
              />
              <ApplicationsPerFund
                className="col-span-2"
                funds={solution?.funds || []}
                data={Object.values(
                  applicationAnalytics.applicationsOverTime
                ).flat()}
              />
              <ApplicationsPerRegion
                className="col-span-2"
                regions={regions}
                data={Object.values(
                  applicationAnalytics.applicationsOverTime
                ).flat()}
              />
            </>
          )}
        </div>
      </main>
      <Toaster />
    </AppLayout>
  )
}

export default Page
