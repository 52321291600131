import { Stat, StatGroup, formatNumber } from '@changex/design-system'

const SimpleStats = ({
  completedSurveys,
  beneficiaries,
  childrenBeneficiaries,
  childrenParticipants,
  participants,
  totalApplications,
  fundedTeams,
  className,
}: {
  completedSurveys: number
  beneficiaries: number
  childrenBeneficiaries: number
  childrenParticipants: number
  participants: number
  totalApplications: number
  fundedTeams: number
  className?: string
}) => {
  return (
    <StatGroup className={className}>
      <Stat
        title="People impacted"
        value={beneficiaries}
        secondaryText={
          childrenBeneficiaries === 1
            ? '1 child'
            : `${formatNumber(childrenBeneficiaries)} children`
        }
      />
      <Stat
        title="Participants"
        value={participants}
        secondaryText={
          childrenParticipants === 1
            ? '1 child'
            : `${formatNumber(childrenParticipants)} children`
        }
      />
      <Stat
        title="Funded teams"
        value={fundedTeams}
        secondaryText={
          completedSurveys === 1
            ? '1 completed impact survey'
            : `${formatNumber(completedSurveys)} completed impact surveys`
        }
      />
      <Stat title="Total applications" value={totalApplications} />
    </StatGroup>
  )
}

export default SimpleStats
