import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Menu as DSMenu } from '@changex/design-system'
import { useSolutionById } from '@features/applications/hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

const Menu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleNavigationClick = (route) => {
    if (route.startsWith('http')) {
      window.location.href = route
      return null
    }
    navigate(route)
  }
  const { solutionId } = useParams()

  const { data } = useSolutionById(solutionId ?? 0)

  const { releaseSiImpactDashboard } = useFlags()

  const getFullUrl = (path, prefix = '') => {
    if (prefix !== '') {
      return `${CHANGEX_ADDRESS}/${prefix}/${data?.slug}/${path}`
    }
    return `${CHANGEX_ADDRESS}/${data?.slug}/${path}`
  }

  return (
    <DSMenu className="max-w-[16rem]">
      <DSMenu.Header>{data?.name}</DSMenu.Header>
      <DSMenu.Items>
        <DSMenu.NavItem
          active={
            !!location.pathname.match(/\/solutions\/[0-9]+\/applications/)
          }
          route={`/solutions/${solutionId}/applications`}
          onNavigationClick={handleNavigationClick}
        >
          Applications
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/impact')}
          onNavigationClick={handleNavigationClick}
        >
          News feed
        </DSMenu.NavItem>
        <DSMenu.NavItem
          active={!!location.pathname.match(/\/solutions\/[0-9]+\/photos/)}
          route={`/solutions/${solutionId}/photos`}
          onNavigationClick={handleNavigationClick}
        >
          Photos
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/events')}
          onNavigationClick={handleNavigationClick}
        >
          Calendar
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/locations/admin')}
          onNavigationClick={handleNavigationClick}
        >
          Locations
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/memberships?role=admin', 'solutions')}
          onNavigationClick={handleNavigationClick}
        >
          Members
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/resources/admin')}
          onNavigationClick={handleNavigationClick}
        >
          Resources
        </DSMenu.NavItem>
        <DSMenu.NavItem
          route={getFullUrl('/payments/history')}
          onNavigationClick={handleNavigationClick}
        >
          Payments
        </DSMenu.NavItem>
        <DSMenu.NavItem
          active={
            !!location.pathname.match(/\/solutions\/[0-9]+\/payments\/funds/)
          }
          route={`/solutions/${solutionId}/payments/funds`}
          onNavigationClick={handleNavigationClick}
        >
          Funds
        </DSMenu.NavItem>
        {releaseSiImpactDashboard && (
          <DSMenu.NavItem
            active={
              !!location.pathname.match(
                /\/solutions\/[0-9]+\/impact\/dashboard/
              )
            }
            route={`/solutions/${solutionId}/impact/dashboard`}
            onNavigationClick={handleNavigationClick}
          >
            Impact dashboard
          </DSMenu.NavItem>
        )}
        <DSMenu.NavItem
          route={getFullUrl('/edit', 'solution_manage')}
          onNavigationClick={handleNavigationClick}
        >
          Edit solution
        </DSMenu.NavItem>
      </DSMenu.Items>
    </DSMenu>
  )
}

export default Menu
