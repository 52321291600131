import { BarChartTable } from '@changex/design-system'
import { TSolutionApplicationsOverTimeItem } from 'shared/api/analytics'
import classNames from 'classnames'
import { TFund } from '@features/applications/types'
import { ComponentProps } from 'react'
import { LocationFunnelStateCamelCase } from '@changex/design-system'

type TProps = {
  funds: TFund[]
  data: TSolutionApplicationsOverTimeItem[]
  className?: string
}

const groupDataByFundId = (
  funds: TFund[],
  data: TSolutionApplicationsOverTimeItem[]
) => {
  return data.reduce((acc, item) => {
    if (!item.fundId) {
      return acc
    }
    const fund = funds.find((fund) => fund.id === item.fundId?.toString())
    if (!acc[item.fundId]) {
      acc[item.fundId] = {
        key: item.fundId,
        label: (
          <div className="flex flex-col gap-0.5">
            <div>{fund?.name || `Fund ${item.fundId}`}</div>
            <div className="text-xs text-gray-500">
              {fund?.internalIdentifier}
            </div>
          </div>
        ),
        total: 0,
        stats: {
          waitlist: 0,
          inProgress: 0,
          funded: 0,
          unsuccessful: 0,
        },
      }
    }
    acc[item.fundId].total++
    switch (item.state) {
      case 'waiting':
        acc[item.fundId].stats.waitlist++
        break
      case LocationFunnelStateCamelCase.PreAllocated:
      case LocationFunnelStateCamelCase.Allocated:
      case LocationFunnelStateCamelCase.Approved:
      case LocationFunnelStateCamelCase.Succeeded:
        acc[item.fundId].stats.inProgress++
        break
      case LocationFunnelStateCamelCase.PaidSeed:
      case LocationFunnelStateCamelCase.Impact:
      case LocationFunnelStateCamelCase.PaidImpact:
        acc[item.fundId].stats.funded++
        break
      case LocationFunnelStateCamelCase.Rejected:
      case LocationFunnelStateCamelCase.Failed:
      case LocationFunnelStateCamelCase.Refunded:
      case LocationFunnelStateCamelCase.FailedImpact:
        acc[item.fundId].stats.unsuccessful++
        break
      default:
        throw new Error(`Unknown state: ${item.state}`)
    }
    return acc
  }, {} as Record<number, ComponentProps<typeof BarChartTable>['rows'][0]>)
}

const ApplicationsPerFund: React.FC<TProps> = ({ funds, data, className }) => {
  const groupedData = groupDataByFundId(funds, data)
  return (
    <div
      className={classNames('flex flex-col rounded bg-white p-4', className)}
    >
      <BarChartTable
        title="Applications per fund"
        primaryColumnLabel="Fund"
        legend={[
          {
            label: 'Waitlist',
            color: 'bg-gray-200',
            key: 'waitlist',
            tooltip: 'Applications which are on the waitlist',
          },
          {
            label: 'In progress',
            color: 'bg-green-500',
            key: 'inProgress',
            tooltip:
              'Applications which have been accepted into the fund, but have not yet received funding (replication inbox, allocated, approved, succeeded)',
          },
          {
            label: 'Funded',
            color: 'bg-teal-800',
            key: 'funded',
            tooltip:
              'Applications which have received funding (paid seed, impact, paid impact)',
          },
          {
            label: 'Unsuccessful',
            color: 'bg-pink-300',
            key: 'unsuccessful',
            tooltip: 'Applications which have failed or been rejected',
          },
        ]}
        rows={Object.values(groupedData)}
      />
    </div>
  )
}

export default ApplicationsPerFund
