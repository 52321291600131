import { useQuery as useJsonApiQuery } from 'jsonapi-react' // TODO: Remove reliance on jsonapi-react and use simple fetch or axios

export const useFetcher = <T>(
  url: string,
  searchParams?: Record<string, string>
) => {
  const { data, meta, isLoading, error } = useJsonApiQuery<T>([
    url,
    searchParams,
  ])
  return { data, meta, isLoading, error }
}
