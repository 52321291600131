// NOTE: We're migrating to using the same API convention as the Next.js app.
// Avoid using the functions below and use the fetcher function instead.

import { http } from '@http'

export const Api = {
  exportSolutionApplicants: async (solutionId: string | number | undefined) => {
    try {
      const response = await http.get(
        `/solution_applications/export?filter[solution_id]=${solutionId}`
      )
      return response
    } catch (err) {
      console.log(err)
    }
  },
}
